@import "../../../assets/scss/abstracts.scss";

.result {
    display: block;
    position: relative;
    & > div {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 2.5%);
        z-index: 10;
        border: 1px solid $white;
        padding: 0.75em 1.5em;
        border-radius: 0.25em;
        background: $white;
        color: $black;
        font-size: 1.25em;
        font-weight: 800;
        letter-spacing: 0.15em;
    }
    button {
        border-radius: 0.25em;
        background: $link-color;
        color: $black;
        font-weight: 800;
        border: 1px solid $black;
        padding: 0.15em 0.75em;
        margin-top: 0.5em;
        text-transform: uppercase;
        &:hover {
            background: $light-grey;
            color: $black;
        }
    }
}
