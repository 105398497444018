// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

@use "sass:meta";

/// Regular font family
$font-stack: "Space Mono", monospace;

/// Colors

:root {
  --color-main: #8ecae6;
  --color-alpha: #219ebc;
  --color-beta: #023047;
  --color-gamma: #ffb703;
  --color-delta: #fb8500;

  --very-light-grey: #f3f3f3;
  --light-grey: #e1e1e1;
  --grey: #999999;
  --dark-grey: #3d3d3d;
  --very-dark-grey: #282828;

  --black: #000000;
  --white: #ffffff;

  --text-color: #e1e1e1;
  --link-color: #ffb703;
  --background-color: #023047;

  --color-alert: #e63946;
}

$color-main: var(--color-main);
$color-alpha: var(--color-alpha);
$color-beta: var(--color-beta);
$color-gamma: var(--color-gamma);
$color-delta: var(--color-delta);

$very-light-grey: var(--very-light-grey);
$light-grey: var(--light-grey);
$grey: var(--grey);
$dark-grey: var(--dark-grey);
$very-dark-grey: var(--very-dark-grey);

$black: var(--black);
$white: var(--white);

$link-color: var(--link-color);
$text-color: var(--text-color);
$background-color: var(--background-color);

$color-alert: var(--color-alert);

$font-size: clamp(14px, 1.8vw, 18px);
$line-height: 1.4em;

/// Breakpoints map
$breakpoints: (
  "xs": 540px,
  "sm": 720px,
  "md": 960px,
  "lg": 1024px,
  "xl": 1200px,
) !default;

/// Container's maximum width
$max-width: map-get($breakpoints, "xl") !default;
