@import "../../assets/scss/abstracts.scss";

.keyboard {
    min-width: 320px;
    display: grid;
    justify-content: center;
    gap: 0.5em;
    grid-template-columns: repeat(3, 3em);
    grid-template-rows: repeat(4, 3em);
}
