@import "../../../assets/scss/abstracts.scss";

.number {
    border: 1px solid $dark-grey;
    background-color: $very-dark-grey;
    aspect-ratio: 1/1;
    padding: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 1.25em;
    line-height: 1em;
    margin: 0.15em;
    opacity: 0.25;
    &.empty {
        opacity: 0.75;
        span {
            opacity: 0;
        }
    }
    &.neutral {
        opacity: 1;
        background-color: $dark-grey;
        color: $white;
    }
    &.exist {
        opacity: 1;
        background-color: $color-gamma;
        color: $black;
    }
    &.ok {
        opacity: 1;
        background-color: $color-main;
        color: $black;
    }
}
