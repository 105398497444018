.btn {
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.icon-menu {
  width: 1em;
  height: 1em;
}

/* Big Menu */

.big-menu {
  position: fixed;
  z-index: 99;
  top: calc(4em + 1px);
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $color-beta;
  transition: transform 0.5s ease;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033;
    z-index: -1;
  }
  &.setting {
    transform: translateX(-100vw);
  }
  &.menu {
    transform: translateX(100vw);
  }
  &.active {
    transform: translateX(0);
  }
}
