// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

html,
body {
  height: 100%;
}

body {
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: $text-color;
  font-size: $font-size;
  line-height: $line-height;
}

#root {
  height: 100%;
}

a {
  color: $link-color;
}

a[type="button"],
button {
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

nav ul {
  @extend .reset-list;
}

.container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 1em;
}

.onlyDesktop {
  display: block;
  @include respond-to(sm) {
    display: none;
  }
}

.onlyMobile {
  display: none;
  @include respond-to(sm) {
    display: block;
  }
}

.App {
  background-color: $background-color;
  color: $text-color;
  min-height: 100%;
}

.App.light {
  --color-main: #8ecae6;
  --color-alpha: #219ebc;
  --color-beta: #f4f4f4;
  --color-gamma: #ef8976;
  --color-delta: #23f5d3;

  --very-light-grey: #f3f300;
  --light-grey: #e1e100;
  --grey: #999900;
  --dark-grey: #3d3d00;
  --very-dark-grey: #282800;

  --black: #ffffff;
  --white: #eeeeee;

  --text-color: #333333;
  --link-color: #ffb703;
  --background-color: #f3f3f3;

  --color-alert: #e63946;
}
