@import "../../assets/scss/abstracts.scss";

.big-menu.setting {
    ul.options {
        @extend .reset-list;
        max-width: 320px;
        margin: 5em auto;
        & > li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 1em 0;
            border-bottom: 1px solid $white;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .label {
        font-weight: bold;
        color: $white;
        text-transform: uppercase;
        em {
            display: block;
            font-style: normal;
            font-weight: 200;
            color: $light-grey;
            text-transform: none;
            font-size: 0.65em;
            line-height: 1em;
        }
    }
    .email {
        border-radius: 0.25em;
        background: $white;
        color: $grey;
        font-weight: 800;
        border: none;
        padding: 0.15em 0.75em;
        &:hover {
            background: $link-color;
            color: $black;
        }
    }
    .levels {
        display: flex;
        direction: row;
        li {
            font-size: 0.75em;
            position: relative;
            text-transform: uppercase;
            background-color: $white;
            color: $grey;
            &.active {
                background-color: $link-color;
                color: $black;
                button {
                    pointer-events: none;
                }
            }
            button {
                display: block;
                padding: 0.35em 0.75em;
                line-height: 1em;
                font-weight: bold;
                background: none;
                border: none;
                color: inherit;
            }
            em {
                font-size: 0.5em;
                font-style: normal;
                line-height: 1em;
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                height: 1.5em;
                padding: 0.25em 0.5em;
                background-color: inherit;
                border-radius: 0.25em 0.25em 0 0;
                transform: translate(-50%, -65%);
            }
            &:first-child {
                border-radius: 0.25em 0 0 0.25em;
            }
            &:last-child {
                border-radius: 0 0.25em 0.25em 0;
            }
        }
    }
}
